import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "C:/xampp/htdocs/hausman/app/src/client/template.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnimateIntroBanner = makeShortcode("AnimateIntroBanner");
const RowLines = makeShortcode("RowLines");
const Decoration = makeShortcode("Decoration");
const Block = makeShortcode("Block");
const Heading = makeShortcode("Heading");
const LearnMore = makeShortcode("LearnMore");
const Paragraph = makeShortcode("Paragraph");
const Row = makeShortcode("Row");
const BlockProjects = makeShortcode("BlockProjects");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AnimateIntroBanner id="banner" className="background_logo dark" background={<><RowLines mdxType="RowLines" /><Decoration from="/sector-commercial/banner.jpg" mdxType="Decoration" /></>} mdxType="AnimateIntroBanner">
  <Block mdxType="Block">
    <Heading mdxType="Heading">
      Commercial Properties
    </Heading>
    <LearnMore elementId="projects_list" color="white" mdxType="LearnMore" />
  </Block>
  <Block className="content_block" mdxType="Block">
    <Paragraph mdxType="Paragraph">Be it shopping malls, office buildings, or real estate developments, our team makes full use of its field expertise and tools to manage, operate, and maintain all systems and components of our clients’ commercial projects.</Paragraph>
  </Block>  
    </AnimateIntroBanner>
    <Row id="projects_list" background={<RowLines mdxType="RowLines" />} mdxType="Row">
  <BlockProjects sector="commercial-properties" flipped={true} mdxType="BlockProjects" />
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      